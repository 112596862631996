import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import SEO from '@src/components/seo'

export default () => {
  useEffect(() => {
    navigate('/gallery/all')
  }, [])
  return <SEO />
}
